<template>
  <div style="width: 100%">
    <LoadingPage :visible="tableLoading"></LoadingPage>

    <div style="width: 100%" class="d-flex flex-wrap" v-show="!tableLoading">
      <v-card
        style="height: 100%; width: 100%; margin: 10px 10px 10px 10px"
        class="border-radius-md pa-3"
      >
        <div class="d-flex" style="align-items: center">
          <h3 class="text-dark mr-2" v-if="!isEdit">每周复盘</h3>
          <div>
            <DayFilter
              ref="dayFilter"
              :returnId="true"
              placeholder="选择日期"
              :autoSave="false"
              inputWidth="120px"
              class="ml-1"
              @change="changeDate"
              v-if="!isEdit"
            ></DayFilter>
            <!-- <vxe-button
              size="medium"
              content="导出"
              class="ml-2"
              status="my-primary"
              @click="exportData"
              v-if="$commonHelpers.getPermissions('reviewnotice.export')"
            ></vxe-button> -->
            <!-- <vxe-button
                size="medium"
                content="打印"
                class="ml-2"
                status="my-primary"
                @click="$refs.reviewDisplay.print()"
                v-if="!isEdit"
              ></vxe-button> -->
            <vxe-button
              size="medium"
              content="打印"
              class="ml-2"
              status="my-primary"
              @click="fullPageCaptures"
              v-if="!isEdit"
            ></vxe-button>
            <!-- <vxe-button
                size="medium"
                content="编辑复盘"
                class="ml-2"
                status="my-primary"
                @click="editReview"
                v-if="!isEdit"
              ></vxe-button>
              <vxe-button
                size="medium"
                content="新建复盘"
                class="ml-2"
                status="my-primary"
                @click="addReview()"
                v-if="!isEdit"
              ></vxe-button>
              <vxe-button
                size="medium"
                content="返回预览"
                class="ml-2"
                status="my-primary"
                v-if="isEdit"
                @click="backToDisplay()"
              ></vxe-button>
              <vxe-button
                @click="AiAnalysis()"
                size="medium"
                style="width: 120px"
                class="ml-2"
                status="my-warning"
              >
                AI每日复盘
              </vxe-button> -->
          </div>
        </div>
        <ReviewDisplay
          v-if="!isEdit && displayContent"
          :displayContent="displayContent"
          ref="reviewDisplay"
        ></ReviewDisplay>
        <ReviewEdit v-if="isEdit" :oldContent="editContent"></ReviewEdit>
      </v-card>
    </div>
  </div>
</template>
<script>
import Bus from "@/components/Bus/bus.js";
import Review from "@/api/review.js";
import ReviewDisplay from "@/components/Review/weekReview/reviewDisplay";
import ReviewEdit from "@/components/Review/weekReview/reviewEdit";
import DayFilter from "@/components/Review/weekReview/dayFilter";
import AddReview from "@/components/Review/weekReview/AddReview";
let html2canvas = require("@/util/html2canvas.js");
export default {
  components: { ReviewDisplay, ReviewEdit, DayFilter },
  props: {},
  data() {
    return {
      tableLoading: false,
      isEdit: false,
      dayList: [],
      selectedReviewId: null,
      displayContent: null,
      editContent: null,
      isDowload: false,
    };
  },
  mounted() {},
  methods: {
    changeDate(val) {
      if (val) {
        //console.log(val);
        this.selectedReviewId = val;
        this.getReviewContent();
      }
    },
    getReviewContent() {
      this.showLoading("获取记录中，请稍候");
      Review.getWeekReviewContent(this.selectedReviewId)
        .then((res) => {
          let weekDataUp = [];
          let weekDataDown = [];
          if (res.data.hot_cncpt && res.data.hot_cncpt.data.length) {
            res.data.hot_cncpt.data.forEach((item) => {
              item.total = item.components.length || 1;
            });
          }
          if (
            res.data.cncpt_data.up.length == 5 &&
            res.data.sw_data.up.length == 5
          ) {
            [0, 1, 2, 3, 4].forEach((item) => {
              weekDataUp.push({
                cncptName: res.data.cncpt_data.up[item]["cncptName"],
                chgRate5: res.data.cncpt_data.up[item]["chgRate5"],
                swcncptName: res.data.sw_data.up[item]["cncptName"],
                swchgRate5: res.data.sw_data.up[item]["chgRate5"],
              });
            });
          }
          if (
            res.data.cncpt_data.down.length == 5 &&
            res.data.sw_data.down.length == 5
          ) {
            [0, 1, 2, 3, 4].forEach((item) => {
              weekDataDown.push({
                cncptName: res.data.cncpt_data.down[item]["cncptName"],
                chgRate5: res.data.cncpt_data.down[item]["chgRate5"],
                swcncptName: res.data.sw_data.down[item]["cncptName"],
                swchgRate5: res.data.sw_data.down[item]["chgRate5"],
              });
            });
          }

          this.displayContent = res.data;
          this.$set(this.displayContent, "weekDataUp", weekDataUp);
          this.$set(this.displayContent, "weekDataDown", weekDataDown);
          //console.log(this.displayContent);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    editReview() {
      this.isEdit = true;
      this.showLoading("获取记录中，请稍候");
      Review.getReviewEdit(this.selectedReviewId)
        .then((res) => {
          this.editContent = res.data;
          //console.log(this.editContent);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    backToDisplay() {
      this.isEdit = false;
      this.getReviewContent();
    },
    addReview() {
      this.$layer.iframe({
        content: {
          content: AddReview,
          parent: this,
          data: {
            iframeData: {},
          },
        },
        area: ["600px", "600px"],
        title: "新建复盘",
        maxmin: false,
        shade: true,
        shadeClose: false,
        cancel: () => {
          //关闭弹窗事件
        },
      });
    },
    AiAnalysis() {
      let reviewId = this.selectedReviewId;
      let day = "";
      let DayArr = this.$refs.dayFilter.selectData;
      for (let i = 0; i < DayArr.length; i++) {
        if (DayArr[i].value == reviewId) {
          day = DayArr[i].label;
          break;
        }
      }
      this.$commonHelpers.openAiAnalysisIframe(
        "AI复盘",
        "https://chatgpt.lhotsetech.com/chat/share?shareId=slpz40ocditis96qxsm315yf",
        this
      );
    },
    exportData() {
      this.showLoading("下载中，请稍候");
      Review.exportEveryday()
        .then((res) => {
          //console.log(this.editContent);
          if (res.code == 0) {
            window.open(res.data.url);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.hideLoading();
        });
    },

    /**
     * 生成图片
     */
    async fullPageCaptures() {
      let that = this;
      this.showLoading("下载中，请稍候！");
      let overMask = document.querySelector("#reviewContentDiv");
      let cloneEle = overMask.cloneNode(true);
      cloneEle.className = "cloneMask";
      cloneEle.style.width =
        document.querySelector("#reviewContentDiv").offsetWidth + "px";
      // cloneEle.style.display = "none";
      document.body.appendChild(cloneEle);
      const canvas = await this.hanledDownload2(cloneEle, null);
      var imgData = canvas.toDataURL("image/jpeg");
      this.fileDownload(imgData);
    },
    /**
     * 生成图片
     */
    hanledDownload2(ref, color) {
      console.log(ref);
      let that = this;
      try {
        return html2canvas(ref, {
          scale: 1,
          backgroudColor: color,
        }).then((canvas) => {
          console.log(canvas);
          return canvas;
        });
      } catch (e) {
        //TODO handle the exception
        this.hideLoading();
        return null;
      }
    },
    /**
     * 下载文件到本地
     */
    fileDownload(downloadUrl) {
      let aLink = document.createElement("a");
      let cloneEle = document.querySelector(".cloneMask");
      aLink.style.display = "none";
      aLink.href = downloadUrl;
      aLink.download = "img.png";
      // 触发点击-然后移除
      document.body.appendChild(aLink);
      aLink.click();
      document.body.removeChild(aLink);
      document.body.removeChild(cloneEle);
      this.hideLoading();
    },
  },
};
</script>
<style scoped="scoped" lang="scss"></style>
